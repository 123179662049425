
import { useState } from "react";
import React from "react";
import SideNav from "./SideNav";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import TopNav from "./TopNav";

const BodyWrapper = ({children, page}) => {
    const [slideNav, setSlideNav] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = Cookies.get("sc_staff");
        if(!token  || token.length < 1) {
            return navigate("/login");
        }

    }, [])


    return (
        <div className={slideNav ? "main-wrapper slide-nav" : "main-wrapper"}>

            <TopNav props={{setSlideNav, slideNav}} />

            <SideNav activePage={page} />
            <div className="page-wrapper">
                <div className="content">
                  { children }
                </div>
            </div>
        </div>
    )
}

export default BodyWrapper;