
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import user from "../../assets/img/user.jpg";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Message } from "../../api";
import Toast from "../../components/Toast";
import Swal from "sweetalert2";
import Loader from "../../components/Loader";

const Messages = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [allMessages, setAllMessages] = useState([]);
    const [filterDate, setFilterDate] = useState({from: "", to: ""});

    useEffect(() => {
        Message.allMessages().then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/"));
            }
            setMessages(res.messages);
            setAllMessages(res.messages);
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/"))
        })
    }, [])

    const dateChange = e => {
        setFilterDate({...filterDate, [e.target.name]: e.target.value});
    }

    const filterMessages = (e) => {
        e.preventDefault();
        if(filterDate.from && filterDate.to) {
            const messages = allMessages.filter(message => {
                const messageDate = new Date(message.date_initiated.toString().slice(0,10)).getTime();
                return (
                    (messageDate >= new Date(filterDate.from).getTime() && messageDate <= new Date(filterDate.to).getTime())
                )
            })
            setMessages(messages);
        }
    }

    
    const deleteMessage = (id) => {
        Swal.fire({
            title: `Are you sure?`,
            text: `Are you sure you want to delete this Message? It will be permanently removed.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "red",
            showConfirmButton: true,
            cancelButtonText: "Ohh No",
            confirmButtonText: "Yes, delete permanently"
        }).then(resp => {
            if (resp.isConfirmed) {
                Message.deleteMessage(id).then(res => {
                    if (res.error) {
                        return Toast.fire({icon: "error", title: res.message});
                    }
                    
                    Message.allMessages().then(res => {
                        setLoading(false);
                        if(res.error) {
                            return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/"));
                        }
                        setMessages(res.messages);
                        setAllMessages(res.messages);
                    })
                }).catch(err => {
                    Toast.fire({icon: "error", title: "Network error. Go back a step and retry"});
                })
            }
        })
    }

    return  (
        <BodyWrapper page={"message"}>
            {
                loading && <Loader data={"Messages"} />
            }
            <div class="row">
                <div class="col-sm-4 col-3">
                    <h4 class="page-title">Messages</h4>
                </div>
                <div class="col-sm-8 col-9 text-right m-b-20">
                    <Link to="/send-message" class="btn btn btn-primary btn-rounded float-right" style={{backgroundColor: "#0a2d44", color: "#fff"}}><i class="fa fa-plus"></i> Send New Message</Link>
                </div>
            </div>
        
            <div class="row filter-row">
                <div class="col-sm-6 col-md-3">
                    <div class="form-group form-focus">
                        <label class="focus-label">From</label>
                        <input className="form-control floating" type="date" name="from" value={filterDate.from} onChange={dateChange} />
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <div class="form-group form-focus">
                        <label class="focus-label">To</label>
                        <input className="form-control floating" type="date" name="to" value={filterDate.to} onChange={dateChange} />
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <a href="#" class="btn btn-success btn-block" onClick={filterMessages}> Filter </a>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table table-striped custom-table">
                            <thead>
                                <tr>
                                    <th>Message Title</th>
                                    <th>No. of recipient(s)</th>
                                    <th>Message Type</th>
                                    <th>Date/Time Initiated</th>
                                    <th>Status</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    messages.map(message => {
                                        return (
                                            <tr>
                                                <td>{message.message_reason}</td>
                                                <td style={{textAlign: "center"}}>
                                                    {message.recipient_type?.toString().toLowerCase().indexOf("all") > -1 ? "All Customers" : message.recipient_type.toUpperCase() }
                                                </td>
                                                <td>{message.message_type.toLowerCase() === "text message" ? "SMS" : message.message_type.toUpperCase()}</td>
                                                <td>{new Date(message.date_initiated).toDateString()} / {new Date(message.date_initiated).toLocaleTimeString()}</td>
                                                <td>
                                                    {
                                                        message.status.toLowerCase() === "sent" ?
                                                        <span class="custom-badge status-green">{message.status}</span>
                                                        :
                                                        message.status.toLowerCase() === "pending" ?
                                                        <span class="custom-badge status-purple">{message.status}</span>
                                                        :
                                                        <span class="custom-badge status-red">{message.status}</span>
                                                    }
                                                </td>
                                                <td class="text-right">
                                                    <div className="dropdown dropdown-action">
                                                        <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <Link className="dropdown-item" to={"/messages/" + message.id}><i className="fa fa-eye m-r-5"></i> View Message</Link>
                                                            
                                                            <a 
                                                            className="dropdown-item" 
                                                            href="#"
                                                            onClick={() => deleteMessage(message.id)}
                                                            >
                                                                <i className="fa fa-trash m-r-5"></i> Delete Message
                                                            </a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default Messages;