

import { useEffect, useRef } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Sale } from "../../api";
import logo from "../../assets/img/brandblocx_logo.png";
import Toast from "../../components/Toast";
import Loader from "../../components/Loader";

import "./print.css"

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const SalesPrint = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [sale, setSale] = useState([]);
    
    const paymentID = location.pathname.split("/")[2];
    const saleID = location.search.split("=")[1];
    
    useEffect(() => {

        console.log(saleID, paymentID);
        Sale.saleDetails()
        // Payment.paymentDetails({saleID, paymentID}).then(res => {
        //     setLoading(false);
        //     if(res.error) {
        //         return Toast.fire({icon: "error", title: res.message})
        //     }
            
        //     setSale(res.paymentDetails);
        // }).catch((err) => {
        //     setLoading(false);
        //     Toast.fire({icon: "error", title: "Data error. Go back a step and refresh."}).then(() => navigate("/payments"))
        // })

    }, [])

    const Print = () => {
        window.print();
    }


    return  (
             
        <div className="ticket printdiv">
        {
            loading && <Loader data={"Payment Details"} />
        }
        
            <button id="btnPrint" className="btn btn-primary" class="hidden-print" onClick={Print}>Print</button>
            <div className="col-12 text-left mj-bj-30">
                <Link to="/sales" className="btn btn-primiary hidden-print"><i className="fa fa-arrow-left"></i> Back to Sales</Link>
            </div>
            <div className="centered">
                
            <img src={logo} alt="Logo" />
            </div>
            <p class="centered">
                <br/>No 1, Olayiwola Road,
                <br/>New oko Oba, Abule egba, Lagos.</p>
            <p className="centered">Phone: 08107562438, 08147936224</p>
            
            <ul className="list-unstyled">
                <li>
                    #REF-{sale.id && sale.id.toString().padStart(3,"0")}
                    <span>{sale.date_recorded && new Date(sale.date_recorded).toDateString().slice(8,15).replace(" ", " " + months[new Date(sale.date_recorded).getMonth()] + ", " )}</span>
                </li>
                <li>Customer: <span>{sale.c_fname} {sale.c_lname}</span></li>
                <li>Payment Method: <span>{sale.mode}</span></li>
            </ul>

            <table>
                <thead>
                    <tr>
                        <th class="description">Item(s)</th>
                        <th class="quantity">Cost</th>
                        <th class="quantity">Quantity</th>
                        <th class="price">Total</th>
                    </tr>
                </thead>
                <tbody>
                {
                    sale.items && sale.items.map(sale_item => {
                        return (
                            <tr>
                                <td className="description">{sale_item.p_name}</td>
                                <td class="quantity">&#8358;{sale_item.price.toLocaleString()}</td>
                                <td class="price">{sale_item.quantity}</td>
                                <td class="price">&#8358;{(sale_item.price * sale_item.quantity).toLocaleString()}</td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>

            <table>
                <tr>
                    <th class="description">Subtotal</th>
                    <td class="quantity">&#8358;{sale.amount && sale.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                </tr>
                <tr>
                    <th class="description">Tax(0%)</th>
                    <td class="quantity">&#8358;0.00</td>
                </tr>
                <tr>
                    <th class="description">TOTAL</th>
                    <td class="quantity">&#8358;{sale.amount && sale.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                </tr>
            </table>
            <div class="centered"><i>Thanks for choosing SOFT!</i></div>

        </div>
    )
}
export default SalesPrint;