
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { CSVLink, CSVDownload } from "react-csv";
import Loader from "../../components/Loader";
import noImage from "../../assets/img/no-image.jpg";

import { useState } from "react";
import { useEffect } from "react";
import { Product } from "../../api";
import Toast from "../../components/Toast";
import UserInfoDropdown from "../../components/UserInfoDropdown";
import { serverurl } from "../../api";

const conditionalRowStyles = [
    {
      when: row => row.quantity < 3,
      style: {
        backgroundColor: 'rgba(255,0,0,0.2)',
        color: '#222',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
]

const columns = [
    { 
        name: 'Image', 
        selector: row => <Link to={`/products/${row.id}`}><img width={30} src={row.image_url ? serverurl + "/products_images/" + row.image_url : noImage} /></Link>, 
        sortable: true 
    },
    { name: 'Product', selector: row => row.name, sortable: true },
    { name: 'Selling Price', selector: row => ("₦" + row.price?.toLocaleString()), sortable: true },
    { name: 'Quantity in store', selector: row => row.quantity, sortable: true },
    { name: 'Sales Today', selector: row => row.salesToday, sortable: true },
    { name: 'Last Sale', selector: row => row.lastSale},
    { name: 'Action', selector: row => row.action},
];

const Products = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [productTypes, setProductTypes] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [productFilter, setProductFilter] = useState([]);

    const [filterData, setFilterData] = useState("name");
    const [filter, setFilter] = useState(false);

    useEffect(() => {
        Product.allProducts().then(res => {
            setLoading(false)
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/"));
            }
            
            setProductsData(res.products.map(product => ({
                ...product, 
                lastSale: product.last_sale && product.last_sale !== "0000-00-00 00:00:00" ? new Date(product.last_sale).toLocaleDateString() : "No sale yet",
                action: <Link to={"/products/" + product.id} className="btn btn-primary">Details &gt;&gt; </Link>
            })));

        }).catch(err => {
            setLoading(false)
            Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/"));
        })

    }, [])

    const searchProduct =e => {
        if(e.target.value.trim().length> 0) {
            setFilter(true);
            let results = [];
            if (filterData === "name") {
                results = productsData.filter(product => product.name.toLowerCase().indexOf(e.target.value.toLowerCase())> -1);
            } else {
                results = productsData.filter(product => product.vendorName.toLowerCase().indexOf(e.target.value.toLowerCase())> -1);
            }
            setProductFilter(results);
        } else {
            setFilter(false);
        }
    }


    return  (
        <BodyWrapper page={"product"}>
            {
                loading && <Loader data={"Products"} />
            }
            <div className="row">
                <div className="col-sm-4 col-3">
                    <h4 className="page-title">Products</h4>
                </div>
                <div className="col-sm-8 col-9 text-right m-b-20">
                    &nbsp; &nbsp;
                    <Link to="/add-product" className="btn btn btn-primary btn-rounded float-right"><i className="fa fa-plus"></i> Add New Product</Link>
                    
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-12 text-right m-b-1">
                    <div className="btn-group btn-group-sm">
                        {/* <button className="btn btn-white">CSV</button> */}
                        <CSVLink 
                        data={ 
                            filter ? 
                            productFilter.map(product => ({ name: product.name, price: product.price, manufacturer_name: product.manufacturer_name, quantity_left: product.quantity, last_sale: product.last_sale }))
                            : 
                            productsData
                        }  
                        className="btn btn-white"
                        >

                            <i className="fa fa-download fa-lg"></i> CSV Download
                        </CSVLink>
                    </div>
                </div>
            </div>
            <div className="row filter-row justify-content">
                <div className="col-sm-6 col-md-4">
                        <label className="focus-labelx">Search With</label>
                    <div className="form-group input-group">
                        <input type="text" className="form-control floatings" placeholder="Search product.." onChange={searchProduct} />
                    </div>
                    
                </div>
                

            </div>

            {/* Data table  */}
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        {
                            filter ?
                            <DataTable columns={columns} data={productFilter} subHeaderAlign={"right"} conditionalRowStyles={conditionalRowStyles} />
                            :
                            <DataTable columns={columns} data={productsData} conditionalRowStyles={conditionalRowStyles} />
                        }
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default Products;