import http from "axios";
import Cookies from "js-cookie";

const serverurl = "https://demoserver.prodiscale.com";
// const serverurl = "http://localhost:4000";

const requestOptions = (datatype = null) => {
    let options = {};
    const token = Cookies.get("sc_staff");
    if (token) {
        options = {
            ...options,
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": datatype,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            }
        }
    }
    return options;
}

const response = (res) => res.data;
const request = {
    get: (url) => http.get(`${serverurl}${url}`, requestOptions("application/json") ).then(response),
    post: (url, data) => http.post(`${serverurl}${url}`, data, requestOptions("application/json")).then(response),
    put:(url, data)=> http.put(`${serverurl}${url}`, data, requestOptions("application/json")).then(response),
    patch:(url, data)=> http.patch(`${serverurl}${url}`, data, requestOptions("application/json")).then(response),
    delete:(url, data)=> http.delete(`${serverurl}${url}`, requestOptions("application/json")).then(response),
}

const Staff = {
    login: (data)=> request.post("/staffs/login", data),
    allStaffs: () => request.get("/staffs"),
    addStaff: (data) => request.post("/staffs/addStaff", data),
    updateStaff: (data, id) => request.patch("/staffs/" + id, data),
    profile: (id) => request.get("/staffs/" + id),
    myprofile: () => request.get("/staffs/myprofile" ),
    deactivateAccount: (id) => request.patch(`/staffs/${id}/deactivate`),
    reactivateAccount: (id) => request.patch(`/staffs/${id}/reactivate`),
    adminDashboard: () => request.get("/staffs/adminDashboard"),
    staffDashboard: () => request.get("/staffs/staffDashboard" ),
    monthlyData: (data) => request.get("/staffs/monthlyData", data),
}

const Customer = {
    addCustomer: (data) => request.post("/customers", data),
    allCustomers: () => request.get("/customers"),
    customerProfile: (id) => request.get("/customers/"+ id),
    updateCustomer: (data, id) => request.patch("/customers/" + id, data),
    deleteCustomer: (id) => request.delete("/customers/" + id),
    getCategories: () => request.get("/customers/categories"),
    addCategory: (data) => request.post("/customers/categories", data),
    deleteCategory: (id) => request.delete("/customers/categories/" + id),
    addCustomersToCategory: (data) => request.post("/customers/addCustomersToCategory", data),
}

const Sale = {
    recordSale: (data) => request.post("/sales/", data),
    allSales: () => request.get("/sales" ),
    saleDetails: (id) => request.get("/sales/" + id),
    staffSales: () => request.get("/sales/staff"),
    updateSaleStatus: (data, id) => request.patch(`/sales/${id}/status`, data),
    deleteSale: (id) => request.delete("/sales/" + id),
    salesReport: (data) => request.post("/sales/report", data)
}

const Message = {
    sendMessage: (data) => request.post("/messages", data),
    allMessages: () => request.get("/messages/"),
    staffMessages: () => request.get("/messages/staff"),
    messageDetails: (id) => request.get("/messages/" + id),
    deleteMessage: (id) => request.delete("/messages/" + id),
    resendMessage: (id) => request.post("/messages/" + id + "/resend"),
}

const Product = {
    addProduct: (data) => request.post("/products", data),
    productDetails: (id) => request.get("/products/" + id),
    updateProduct: (data, id) => request.patch("/products/" + id, data),
    allProducts: () => request.get("/products"),
    deleteProduct: (id) => request.delete("/products/" + id )
}


export {Staff, Customer, Sale, Message, Product, serverurl};