
import './App.css';
import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/dataTables.bootstrap4.min.css";
import "./assets/css/bootstrap-datetimepicker.min.css";
import "./assets/css/select2.min.css";
import "./assets/css/style.css";

import {BrowserRouter, Route, Routes} from "react-router-dom";

import Dashboard from './pages/Dashboard';
import Customers from './pages/Customer/Customers';
import Sales from './pages/Sale/Sales';
import Staffs from './pages/Staff/Staffs';
import Messages from './pages/Message/Messages';
import CustomerProfile from './pages/Customer/CustomerProfile';
import Login from './pages/Login';
import SalesDetails from './pages/Sale/SalesDetails';
import EditCustomerProfile from './pages/Customer/EditCustomerProfile';
import StaffProfile from './pages/Staff/StaffProfile';
import AddCustomer from './pages/Customer/AddCustomer';
import RecordSale from './pages/Sale/RecordSale';
import EditStaffProfile from './pages/Staff/EditStaffProfile';
import AddStaff from './pages/Staff/AddStaff';
import SendMessage from './pages/Message/SendMessage';
import MessageDetails from './pages/Message/MessageDetails';
import ForgetPassword from './pages/ForgetPassword';
import MyProfile from './pages/Staff/MyProfile';
import EditMyProfile from './pages/Staff/EditMyProfile';
import CustomerCategories from './pages/Customer/Categories';

import Products from './pages/Product/Products';
import ProductDetails from './pages/Product/ProductDetails';
import EditProductDetails from './pages/Product/EditProduct';
import AddProduct from './pages/Product/AddProduct';
import SalesPrint from './pages/Sale/SalesPrint';
import SalesReports from './pages/Sale/SalesReport';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Dashboard />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/login' element={<Login />} />
        <Route path='/forgot-password' element={<ForgetPassword />} />
        <Route path='/myprofile' element={<MyProfile />} />
        <Route path='/myprofile/edit' element={<EditMyProfile />} />

        <Route path='/customers' element={<Customers />} />
        <Route path='/customers/categories' element={<CustomerCategories />} />
        <Route path='/customers/:id' element={<CustomerProfile />} />
        <Route path='/customers/:id/edit' element={<EditCustomerProfile />} />
        <Route path='/add-customer' element={<AddCustomer />} />

        <Route path='/sales' element={<Sales />} />
        <Route path='/sales-report' element={<SalesReports />} />
        <Route path='/sales/:id' element={<SalesDetails />} />
        <Route path='/record-sale' element={<RecordSale />} />

        <Route path='/payment-print/:id' element={<SalesPrint />} />

        <Route path='/staffs' element={<Staffs />} />
        <Route path='/staffs/:id' element={<StaffProfile />} />
        <Route path='/staffs/:id/edit' element={<EditStaffProfile />} />
        <Route path='/add-staff' element={<AddStaff />} />

        <Route path='/messages' element={<Messages />} />
        <Route path='/messages/:id' element={<MessageDetails />} />
        <Route path='/send-message' element={<SendMessage />} />
        
        <Route path='/products' element={<Products />} />
        <Route path='/products/:id' element={<ProductDetails />} />
        <Route path='/products/:id/edit' element={<EditProductDetails />} />
        <Route path='/add-product' element={<AddProduct />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
