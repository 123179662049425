
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import { Link, useLocation, useNavigate, useRoutes } from "react-router-dom";

import user from "../../assets/img/user.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { Staff } from "../../api";
import Toast from "../../components/Toast";
import Cookies from "js-cookie";
const staffRolesCodes = {sales_rep: "Sales Representative", receptionist: "Receptionist", admin: "Administrator"};

const MyProfile = () => {
    const navigate = useNavigate();

    const [staff, setStaff] = useState({});

    useEffect(() => {
        Staff.myprofile().then(res => {
            if(res.error || !res.success) {
                return Toast.fire({icon: "error", title: res.message }).then(() => navigate("/staffs") );
            }
            setStaff(res.staffProfile);
        }).catch(err => {
            Toast.fire({icon: "error", title: "Network error. Go back a step and refresh"}).then(()=> navigate("/staffs"));
        })
    }, [])


    return  (
        <BodyWrapper page={"staff"}>
            <div className="row">
                <div className="col-12 text-left mj-bj-30">
                    <Link to="/staffs" className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Staffs</Link>
                </div>
                <div className="col-sm-7 col-6">
                    <h4 className="page-title">My Profile</h4>
                </div>

                <div className="col-sm-5 col-6 text-right m-b-30">
                    <Link to={"/myprofile/edit"} className="btn btn-primary btn-rounded"><i className="fa fa-pencil"></i> Edit Profile</Link>
                </div>
            </div>
            <div className="card-box profile-header">
                <div className="row">
                    <div className="col-md-12">
                        {
                        staff &&
                        <div className="profile-view">
                            <div className="profile-img-wrap">
                                <div className="profile-img">
                                    <a href="#"><img className="avatar" src={staff.avatar_url || user} alt={staff.fname} /></a>
                                </div>
                            </div>
                            <div className="profile-basic">
                                <div className="row align-items-center">
                                    <div className="col-md-5">
                                        <div className="profile-info-left">
                                            <h3 className="user-name m-t-0 mb-0">{staff.fname} {staff.lname}</h3>
                                            <small className="text-muted">{staffRolesCodes[staff.role]}</small>
                                            <div className="staff-id">Staff ID : STAFF-{staff.id && staff.id.toString().padStart(3, "0")}</div>
                                            <div className="staff-id">
                                                {
                                                    staff.account_deactivated ?
                                                    <span className="custom-badge status-red">Deactivated</span>
                                                    :
                                                    <span className="custom-badge status-green">Active</span>
                                                }
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <ul className="personal-info">
                                            <li>
                                                <span className="title">Phone:</span>
                                                <span className="text"><a href="#">{staff.phone}</a></span>
                                            </li>
                                            <li>
                                                <span className="title">Email:</span>
                                                <span className="text"><a href="#">{staff.email}</a></span>
                                            </li>
                                            <li>
                                                <span className="title">Address:</span>
                                                <span className="text">{staff.address || "Not Provided"}</span>
                                            </li>
                                            <li>
                                                <span className="title">Gender:</span>
                                                <span className="text">{staff.gender|| "Not Provided"}</span>
                                            </li>
                                            <li>
                                                <span className="title">Employement Date:</span>
                                                <span className="text">{new Date(staff.employment_date).toDateString()}</span>
                                            </li>
                                            <br />
                                            <li>
                                                <span className="title">Date Added:</span>
                                                <span className="text">{new Date(staff.date_added).toDateString()}</span>
                                            </li>
                                            <li>
                                                <span className="title">Total Sales:</span>
                                                <span className="text">{staff.recorded_sales}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        }                       
                    </div>
                </div>
            </div>
            <div className="profile-tabs">
                <ul className="nav nav-tabs nav-tabs-bottom">
                    <li className="nav-item"><a className="nav-link active" href="#about-cont" data-toggle="tab">Sales Recorded</a></li>
                    <li className="nav-item"><a className="nav-link" href="#bottom-tab2" data-toggle="tab">Messages Sent</a></li>
                </ul>

                <div className="tab-content">
                    <div className="tab-pane show active" id="about-cont">
                        <div className="row">
                            <div className="col-md-12">
                                {
                                    staff &&
                                <div className="card-box">
                                    <h3 className="card-title">Sales Recorded</h3>
                                    <div className="experience-box">
                                        {
                                            (staff.sales && staff.sales.length > 0) && ( 
                                            <ul className="experience-list">
                                                {
                                                    staff.sales.sort((a, b) => new Date(b.date_recorded).getTime() - new Date(a.date_recorded).getTime()).map(sale => {
                                                        return (
                                                        <li key={sale.id}>
                                                            <div className="experience-user">
                                                                <div className="before-circle"></div>
                                                            </div>
                                                            <div className="experience-content">
                                                                <div className="timeline-content">
                                                                    <Link to={`/sales/${sale.salesid}?sid=${sale.id}`} className="name">#{sale.salesid}</Link>
                                                                    <div>&#8358;{sale.amount.toLocaleString(4, "0")}</div>
                                                                    <span className="time">{new Date(sale.date_recorded).toDateString()}</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            )
                                        }
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="bottom-tab2">
                        <div className="row">
                            <div className="col-md-12">
                                {
                                    staff &&
                                <div className="card-box">
                                    <h3 className="card-title">All Messages</h3>
                                    <div className="experience-box">
                                        <ul className="experience-list">
                                            {/* <li>
                                                <div className="experience-user">
                                                    <div className="before-circle"></div>
                                                </div>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <Link to="/sales/123" className="name">Welcome to Soft Collections..</Link>
                                                        <div>Welcome Message</div>
                                                        <span className="time">June 12, 2021</span>
                                                    </div>
                                                </div>
                                            </li> */}
                                            
                                        </ul>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default MyProfile;