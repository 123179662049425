
import BodyWrapper from "../components/Layouts/BodyWrapper";
import { Link, useNavigate } from "react-router-dom";
import StatChart from "../components/Chart";
import user from "../assets/img/user.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { Staff } from "../api";
import Toast from "../components/Toast";
import Cookies from "js-cookie";
import { CustomerStatChart } from "../components/Chart";
import Loader from "../components/Loader";

const monthsData = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const Dashboard = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({sales: [], staffs: [], customers: [], recentSales: []});
    const [months, setMonths] = useState([]);
    const [monthlySales, setMonthlySales] = useState([]);
    const [currentSaleMonth, setCurrentSaleMonth] = useState([]);

    useEffect(() => {

        const currentDate = {month: new Date().getMonth(), year: new Date().getFullYear()};
        const oct2022 = {month: new Date("20 October, 2022").getMonth(), year: new Date("20 October, 2022").getFullYear()};
        const monthsCount = ((currentDate.year - oct2022.year) * 12) - (oct2022.month - currentDate.month);

        const newMonths = [];
        for (let i = monthsCount; i >= 0; i--) {
            const currentMonth = (oct2022.month + i) % 12;
            const currentYear = Math.floor((oct2022.month + i) / 12) + (oct2022.year);
            const monthData = monthsData[currentMonth]  + ", " + currentYear;
            newMonths.push(monthData)
        }
        setMonths(newMonths);

        Staff.adminDashboard().then((res) => {
            setLoading(false);
            if(res.error){
                return Toast.fire({icon: "error", title: res.message}).then(() => {
                    navigate("/login")
                }) 
            }
            setData(res.dashboardDetails);
            setMonthlySales(res.dashboardDetails.sales)
        }).catch((err) => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error. Log out and relogin."})
            .then(() => {
                Cookies.remove("sc_staff");
                navigate("/login")
            })
        })
        
    }, [])

    
    const getMonthlySales = (e) => {
        setCurrentSaleMonth(e.target.value);
        if (e.target.value !== "all") {
            setLoading(true);
            Staff.monthlyData({date: e.target.value.replace(",", "")}).then(res => {
                setLoading(false);
                if(res.error) {
                    return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/login"));
                }
                setMonthlySales(res.sales);
            })
        } else {
            setMonthlySales(data.sales);
        }
    }

    return (
        <BodyWrapper page={"dashboard"}>
            
            {
                loading && <Loader data={"Dashboard data"} />
            }

            <div className="col-4"></div>
            <select name="" id="" value={currentSaleMonth} className="form-control col-md-2 mb-2" onChange={getMonthlySales}>
                <option value="all">All Time</option>
                <option value={`01 ${new Date().getFullYear()}`}>This Year</option>
                {
                    months && months.reverse().map((month, i) => {
                        return i === 0 ? <option key={i} value={month}>This Month</option> : <option key={i} value={month}>{month}</option>
                    })
                }
            </select>

            <div className="row">
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <Link to={"/customers"}>
                        <div className="dash-widget">
                            <span className="dash-widget-bg2"><i className="fa fa-users"></i></span>
                            <div className="dash-widget-info text-right">
                                <h3 style={{color: "#666"}}>{(3445).toLocaleString("en-US").padStart(4, "0")}</h3>
                                <span className="widget-title2">Customers <i className="fa fa-check" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <Link to={"/sales"}>
                        <div className="dash-widget">
                            <span className="dash-widget-bg3"><i className="fa fa-handshake-o" aria-hidden="true"></i></span>
                            <div className="dash-widget-info text-right">
                                <h3 style={{color: "#666"}}>{(2214).toLocaleString("en-US").padStart(4, "0")}</h3>
                                <span className="widget-title3">Sales <i className="fa fa-check" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                    <Link to={"/payments"}>
                        <div className="dash-widget">
                            <span className="dash-widget-bg1"><i className="fa fa-money" aria-hidden="true"></i></span>
                            <div className="dash-widget-info text-right">
                                <h3 style={{color: "#666"}}>&#8358;{
                                    (23415300).toLocaleString("en-US")
                                }</h3>
                                <span className="widget-title1">Revenue <i className="fa fa-check" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="chart-title">
                                <h4>Total Sales</h4>
                                {/* <span className="float-right"><i className="fa fa-caret-up" aria-hidden="true"></i> 15% Higher than Last Month</span> */}
                            </div>

                            {
                                (data.sales && data.sales.length > 0) && <StatChart sales={data.sales} />
                            }

                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            {/* <div className="chart-title"> */}
                            <div className="chart-title">
                                <h4>New Customers</h4>
                                {/* <span className="float-right"><i className="fa fa-caret-up" aria-hidden="true"></i> 15% Higher than Last Month</span> */}
                            </div>	
                            {/* <canvas id="linegraph2"></canvas> */}

                            {
                                (data.customers && data.customers.length > 0) && <CustomerStatChart customers={data.customers} />
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title d-inline-block">Recent Sales</h4> <Link to="/sales" className="btn btn-primary float-right">View all</Link>
                        </div>
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <thead className="d-none">
                                        <tr>
                                            <th>Customer Name</th>
                                            <th>Sales Time</th>
                                            <th className="text-right">Status</th>
                                        </tr>
                                    </thead>
                                    {
                                        data.recentSales.length > 0 && 
                                    <tbody>
                                        {
                                            data.recentSales.map(sale => {
                                                return (
                                                        
                                                    <tr key={sale.id}>
                                                        <td>
                                                            <Link className="avatar" to={"/customers/" + sale.customerid}>{sale.c_fname[0]}</Link>
                                                            <h2><Link to={"/customers/" + sale.customerid}>{sale.c_fname} <span>3:30PM</span></Link></h2>
                                                        </td>  
                                                        <td>
                                                            <h5 className="time-title p-0">Product</h5>
                                                            <p>{sale.products_quantity} pieces</p>
                                                        </td>
                                                        <td className="text-right">
                                                            <Link to={`/sales/${sale.salesid}?sid=${sale.id}`} className="btn btn-outline-primary take-btn">View</Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title d-inline-block">New Customers </h4> <Link to="/customers" className="btn btn-primary float-right">View all</Link>
                        </div>
                        <div className="card-block">
                            <div className="table-responsive">
                                <table className="table mb-0 new-patient-table">
                                    <tbody>
                                        {
                                            data.customers.map((customer, i) => {
                                                return (
                                                    (i < 5) && (<tr>
                                                        <td>
                                                            <img width="28" height="28" className="rounded-circle" src={user} alt="" /> 
                                                            <h2>{customer.fname} {customer.lname}</h2>
                                                        </td>
                                                        <td>{customer.address}</td>
                                                        <td>
                                                            <Link to={"/customers/" + customer.id} className="btn btn-outline-primary take-btn float-right">Profile&gt;&gt;</Link>
                                                        </td>
                                                    </tr>)
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default Dashboard;