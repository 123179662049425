import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            display: true
        },
        title: {
        //   display: true,
        //   text: 'Chart.js Line Chart',
        },
        tooltips: {
            mode: 'index',
            intersect: false,
        }
    }
};

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', "Aug", "Sep", "Oct", "Nov", "Dec"];

// export const data = {
//   labels,
//   datasets: [
//     {
//       fill: true,
//       label: 'Dataset 2',
//     //   data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       data: [200, 40, 800, 230, 10, 339, 73],
//       borderColor: 'rgb(53, 162, 235)',
//       backgroundColor: 'rgba(53, 162, 235, 0.5)',
//     },
//   ],
// };

export default function StatChart({days, sales}) {
  const data = [];
  [0,1,2,3,4,5,6,7,8,9,10,11].forEach((num) => {
    const monthSales = sales && sales.filter(sale => {
      const currentMonth  = new Date(new Date().setMonth(num)).setDate(1);
      const nextMonth  = new Date(new Date().setMonth(num+1)).setDate(1);
      return (
        new Date(sale.date_recorded).getTime() >= currentMonth && new Date(sale.date_recorded).getTime() < nextMonth
      )
    });
    data.push(monthSales && monthSales.length);
  });

  return <Line options={options} data={
    {
        labels,
        datasets: [
          {
            fill: true,
            label: '',
            data: [55,85, 80, 102, 100, 99, 98, 103, 99,101,100,115],
            // data: data.slice(0, new Date().getMonth()+1),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      }
  } />;
}

export function CustomerStatChart({customers}) {
  const data = [];
  [0,1,2,3,4,5,6,7,8,9,10,11].forEach((num) => {
    const monthCustomers = customers && customers.filter(customer => {
      const currentMonth  = new Date(new Date().setMonth(num)).setDate(1);
      const nextMonth  = new Date(new Date().setMonth(num+1)).setDate(1);
      return (
        new Date(customer.date_added).getTime() >= currentMonth && new Date(customer.date_added).getTime() < nextMonth
      )
    });
    data.push(monthCustomers && monthCustomers.length);
  });

  return <Line options={options} data={
    {
        labels,
        datasets: [
          {
            fill: true,
            label: '',
            data: [35, 70, 65, 70, 50, 52, 55, 50, 75, 54, 63, 82],
            // data: data.slice(0, new Date().getMonth()+1),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      }
  } />;
}
