
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Product } from "../../api";
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import Toast from "../../components/Toast";
import Loader from "../../components/Loader";

const AddProduct = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({
        name: "",
        quantity: 1,
        price: "",
        description: ""
    });


    const [imageFile, setImageFile] = useState({});
    const [loading, setLoading] = useState(false);

    const inputChange = e => {
        setForm({...form, [e.target.name]: e.target.value });
    }

    const fileChange = e => {
        const uploadedFile = e.target.files[0];
        if (uploadedFile.type.indexOf("image") < 0) {
            return Toast.fire({icon: "error", title: "Only Image files are allowed"})
        }
        setImageFile(uploadedFile);
    }

    const addProduct = (e) => {
        e.preventDefault();
        setLoading(true);
        
        if (imageFile && imageFile.type && imageFile.type.indexOf("image") < 0) {
            setLoading(false);
            return Toast.fire({icon: "error", title: "Only Image files are allowed"})
        }

        const productForm = new FormData();
        for (const key in form) {
            if (form.hasOwnProperty(key)) {
                productForm.append(key, form[key]);
            }
        }
        productForm.append("image", imageFile);
        
        Product.addProduct(productForm).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message });
            }
            Toast.fire({icon: "success", title: res.message }).then(() => {
                navigate("/products/" + res.productID);
            });
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error"});
        })
    }

    useEffect(() => {

    })

    return  (
        <BodyWrapper page={"product"}>

            <div className="row">
                <div className="col-12 text-left">
                    <Link to={"/products/"} className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Back to Products</Link>
                </div>
                <div className="col-lg-8 offset-lg-2">
                    <h4 className="page-title">Add Product</h4>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <form onSubmit={addProduct}>
                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Product Name <span className="text-danger">*</span></label>
                                    <input className="form-control" name="name" type="text" onChange={inputChange} value={form.name} />
                                </div>
                            </div>
                            
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Quantity in Store <span className="text-danger">*</span></label>
                                    <input type="number" className="form-control" name="quantity" value={form.quantity}  onChange={inputChange} min="1"/>
                                </div>
                            </div>

                            
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group ">
                                    <label>Piece Selling Price (in NGN) <span className="text-danger">*</span></label>
                                    <input type="number" className="form-control" name="price" value={form.price} onChange={inputChange}/>
                                </div>
                            </div>


                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Product Image (optional)</label>
                                    <input type="file" className="form-control" name="image"  onChange={fileChange}/>
                                </div>
                            </div>

                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>Product Description (optional)</label>
                                    <textarea className="form-control" name="description" onChange={inputChange} value={form.description} cols="30" rows="3"></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="m-t-20 text-center">
                            <button className="btn btn-primary submit-btn">{loading ? "Adding product..." : "Add Product"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default AddProduct;