
import { useEffect } from "react";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/img/brandblocx_logo.png";
import user from "../../assets/img/user.jpg";
import notificationSound from "../../assets/audio/notification.mp3";
import { useRef } from "react";  

const TopNav = ({props}) => {
    const navigate = useNavigate();

    const logout= (e) => {
        e.preventDefault();
        Cookies.remove("sc_staff");
        navigate("/login");
    }

    const audioRef = useRef(null);

    useEffect(() => {
        const token = Cookies.get("sc_staff");
        if(!token  || token.length < 1) {
            return navigate("/login");
        }

    }, [])



    return (
        <div className="header" style={{backgroundColor: "#0a2d44"}}>
			<div className="header-left">
				<a href="/" className="logo">
					<img src={logo} width="45" alt="" /> <span>Brandblocx</span>
				</a>
			</div>
			<a id="toggle_btn" href="#"  onClick={() => props.setSlideNav(val => !val)}><i className="fa fa-bars"></i></a>

            <a id="mobile_btn" className="mobile_btn float-left" href="#sidebar" onClick={() => props.setSlideNav(val => !val)}><i className="fa fa-bars"></i></a>

            <ul className="nav user-menu float-right">
           
                <li className="nav-item dropdown has-arrow">
                    <a href="#" className="dropdown-toggle nav-link user-link" data-toggle="dropdown">
                        <span className="user-img">
							<img className="rounded-circle" src={user} width="24" alt="Admin" />
						</span>
						<span>Admin</span>
                    </a>
					<div className="dropdown-menu">
						<Link className="dropdown-item" to="/myprofile">My Profile</Link>
						{/* <a className="dropdown-item" href="edit-profile.html">Edit Profile</a>
						<a className="dropdown-item" href="settings.html">Settings</a> */}
						<a className="dropdown-item" href="#" onClick={logout}>Logout</a>
					</div>
                </li>
            </ul>
            <div className="dropdown mobile-user-menu float-right">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                <div className="dropdown-menu dropdown-menu-right">
						<Link className="dropdown-item" to="/myprofile">My Profile</Link>
						<a className="dropdown-item" href="#" onClick={logout}>Logout</a>
                </div>
            </div>

            <audio src={notificationSound} typeof="audio/mp3" ref={audioRef}></audio>
        </div>
    )
}

export default TopNav;