
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Staff } from "../../api";
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import Toast from "../../components/Toast";

const AddStaff = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({fname: "", lname: "", phone: "", email: "", role: "sales_rep", employment_date: "", password: "", confirmPassword: ""});
    const [loading, setLoading] = useState(false);

    const inputChange = e => {
        setForm({...form, [e.target.name]: e.target.value });
    }

    const submitForm = e => {
        e.preventDefault();
        setLoading(true);
        Staff.addStaff(form).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message });
            }
            Toast.fire({icon: "success", title: res.message }).then(() => {
                navigate("/staffs/" + res.staffID);
            });
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error"});
        })
    }

    return  (
        <BodyWrapper page={"staff"}>
            
            <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <h4 className="page-title">Add Staff</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <form onSubmit={submitForm}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>First Name <span className="text-danger">*</span></label>
                                        <input className="form-control" type="text" name="fname" onChange={inputChange} value={form.fname} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Last Name<span className="text-danger">*</span></label>
                                        <input className="form-control" type="text" name="lname" onChange={inputChange} value={form.lname} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Phone Number <span className="text-danger">*</span></label>
                                        <input className="form-control" type="tel" name="phone" onChange={inputChange} value={form.phone} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Email <span className="text-danger">*</span></label>
                                        <input className="form-control" type="email" name="email" onChange={inputChange} value={form.email}  />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Employemnt Date<span className="text-danger">*</span></label>
                                        <input className="form-control" type="date" name="employment_date" value={form.employment_date} onChange={inputChange} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Role<span className="text-danger">*</span></label>
                                        <select className="form-control" name="role" onChange={inputChange} value={form.role} >
                                            <option value={"sales_rep"}>Sales Representative</option>
                                            <option value={"admin"}>Administrator (Full Access)</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Password<span className="text-danger">*</span></label>
                                        <input className="form-control" type="password" name="password" onChange={inputChange} value={form.password}  />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Confirm Password<span className="text-danger">*</span></label>
                                        <input className="form-control" type="password" name="confirmPassword" value={form.confirmPassword} onChange={inputChange}  />
                                    </div>
                                </div>
                            </div>
                            <div className="m-t-20 text-center">
                                <button className="btn btn-primary submit-btn">{loading ? "Adding staff..." : "Add Staff"}</button>
                            </div>
                        </form>
                    </div>
                </div>
        </BodyWrapper>
    )
}

export default AddStaff;