
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Staff } from "../../api";
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import Toast from "../../components/Toast";
import errorImg from "../../assets/img/sent.png";
import Loader from "../../components/Loader";

const EditStaffProfile = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({fname: "", lname: "", phone: "", phone2: "", email: "", role: "", employment_date: null, gender: "Male", address: ""});

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    
    const inputChange = e => {
        setForm({...form, [e.target.name]: e.target.value });
    }

    const staffID = useLocation().pathname.split("/")[2];

    useEffect(() => {
        Staff.profile({staffID}).then(res => {
            setPageLoading(false);
            if(res.error || !res.success) {
                return Toast.fire({icon: "error", title: res.message }).then(() => navigate("/staffs") );
            }
            setForm({...res.staffProfile, employment_date: res.staffProfile.employment_date.toString().slice(0, 10), gender: res.staffProfile.gender || "Male"});
        }).catch(err => {
            setPageLoading(false);
            Toast.fire({icon: "error", title: "Network error. Go back a step and refresh"}).then(()=> navigate("/staffs"));
        })
    }, [])

    const submitForm = e => {
        setLoading(true);
        Staff.updateStaff({...form, staffID}).then(res=> {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title: res.message}).then(() => navigate("/staffs/" + staffID));
        }).catch(err => {
            setLoading(false);
            return Toast.fire({icon: "error", title: "Network error. Go a step back and refresh."}).then(() => navigate("/staffs"));
        })
    }

    const deactivateAccount = () => {
        Staff.deactivateAccount({id:staffID}).then(res => {
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title: "Account deactivated successfully"}).then(() => navigate("/staffs/" + staffID));;
        }).catch(err => {
            Toast.fire({icon: "error", title: "Network error"});
        })
    }

    const reactivateAccount = () => {
        Staff.reactivateAccount({id:staffID}).then(res => {
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title: "Account now activated successfully"}).then(() => navigate("/staffs/" + staffID));;
        }).catch(err => {
            Toast.fire({icon: "error", title: "Network error"});
        })
    }

    return  (
        <BodyWrapper page={"staff"}>
            
            {
                pageLoading && <Loader data={"Staff Profile Information"} />
            }

            <div className="row">
                <div className="col-sm-5 col-6 text-left m-b-30">
                    <Link to={"/staffs/" + staffID} className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Back to profile</Link>
                </div>
                <div className="col-sm-12">
                    <h4 className="page-title">Update Staff Profile</h4>
                </div>
            </div>
            <form>
                <div className="card-box">
                    <h3 className="card-title">Basic Information</h3>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="profile-basc">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group form-focus select-focus">
                                            <label className="focus-label">Staff ID</label>
                                            <input type="text" className="form-control floating" value={"STAFF-"+ staffID.toString().padStart(3, "0")} readOnly />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-focus select-focus">
                                            <label className="focus-label">Account Email</label>
                                            <input type="email" className="form-control floating" name="email" value={form.email} onChange={inputChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-focus">
                                            <label className="focus-label">First Name</label>
                                            <input type="text" className="form-control floating" name="fname" value={form.fname} onChange={inputChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-focus">
                                            <label className="focus-label">Last Name</label>
                                            <input type="text" className="form-control floating" name="lname" value={form.lname} onChange={inputChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-focus select-focus">
                                            <label className="focus-label">Gender</label>
                                            <select className="select form-control floating" name="gender" value={form.gender} onChange={inputChange}>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-focus select-focus">
                                            <label className="focus-label">Role</label>
                                            <select name="role" id="" className="form-control" value={form.role} onChange={inputChange}>
                                                <option value="sales_rep">Sales Representative</option>
                                                <option value="receptionist">Receptionist</option>
                                                <option value="admin">Admnistrator (Full Access)</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-box">
                    <h3 className="card-title">Other Information</h3>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group form-focus">
                                <label className="focus-label">Phone Number</label>
                                <input type="tel" className="form-control floating" name="phone" value={form.phone} onChange={inputChange} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group form-focus">
                                <label className="focus-label">Alternative Phone Number</label>
                                <input type="tel" className="form-control floating" name="phone2" value={form.phone2} onChange={inputChange} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group form-focus">
                                <label className="focus-label">Employment Date</label>
                                <input type="date" className="form-control" name="employment_date" value={form.employment_date} onChange={inputChange} />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group form-focus">
                                <label className="focus-label">Address</label>
                                <input type="text" className="form-control floating" name="address" value={form.address} onChange={inputChange} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center m-t-20">
                    <button className="btn btn-primary submit-btn" type="button" style={{color: "#000"}} onClick={submitForm}>{loading ? "Saving..." : "Save"}</button>
                </div>
                <div className="text-center m-t-20">
                    {
                        form.account_deactivated ?
                        <button className="btn btn-success" type="button"  data-toggle="modal" data-target="#reactivate_staff">Reactivate Staff Account</button>
                        :
                        <button className="btn btn-danger" type="button"  data-toggle="modal" data-target="#delete_doctor">Deactivate Staff Account</button>
                    }
                </div>
            </form>
            
            <div id="delete_doctor" className="modal fade delete-modal" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            <img src={errorImg} alt="" width="50" height="46" />
                            <h3>Are you sure want to deactivate {form.fname} {form.lname}'s account? They won't be able to login to the system to record sales henceforth.</h3>
                            <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Close</a>
                            &nbsp;
                                <button className="btn btn-danger" data-dismiss="modal" onClick={deactivateAccount}>YES</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div id="reactivate_staff" className="modal fade delete-modal" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            <img src={errorImg} alt="" width="50" height="46" />
                            <h3>Are you sure want to <b>reactivate</b> {form.fname} {form.lname}'s account? They will now be able to login to the system to record sales henceforth.</h3>
                            <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Close</a>
                            &nbsp;
                                <button className="btn btn-success" data-dismiss="modal" onClick={reactivateAccount}>YES</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </BodyWrapper>
    )
}

export default EditStaffProfile;