
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { CSVLink, CSVDownload } from "react-csv";
import Loader from "../../components/Loader";

import user from "../../assets/img/user.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { Customer, Staff } from "../../api";
import Toast from "../../components/Toast";
import UserInfoDropdown from "../../components/UserInfoDropdown";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const columns = [
    { name: 'Name', selector: row => row.name, sortable: true },
    { name: 'Purchases', selector: row => row.purchases, sortable: true },
    { name: 'Address', selector: row => row.address, sortable: true },
    { name: 'Phone', selector: row => row.phone, sortable: true },
    { name: 'Email', selector: row => row.email, sortable: true },
    { name: 'Last Purchase Date', selector: row => row.purchases === 0 ? "---" : row.last_purchase, sortable: true },
    { name: 'Action', selector: row => row.action, },
];

const Customers = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [customersData, setCustomersData] = useState([]);
    const [customerFilter, setCustomerFlter] = useState([]);

    const [filterData, setFilterData] = useState("name");
    const [filter, setFlter] = useState(false);

    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");

    useEffect(() => {
        Customer.allCustomers({}).then(res => {
            setLoading(false)
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/"));
            }
            setCustomers(res.customers);
            setCustomersData(res.customers.map(customer => ({
                ...customer, name: customer.fname + " " + customer.lname, 
                action: <UserInfoDropdown id={customer.id}/>, 
                last_purchase: new Date(customer.last_purchase).toLocaleDateString()
            })));

            document.querySelectorAll(".rdt_TableCell").forEach(elem => {
                if(!elem.nextElementSibling) {
                    elem.classList.remove("bMgaAx");
                }
            })

            const cid = location.search;
            if(cid && cid.indexOf("cid") > -1) {
                const id = cid.split("=")[1];
                if(id) {
                    console.log(id);

                    let categoryResults = [];
                    categoryResults = customersData.filter(customer => customer.category == id);
                    setFlter(true);
                    setCustomerFlter(categoryResults);
                    setSelectedCategory(id)
                }
            }
        }).catch(err => {
            console.log(err);
            setLoading(false)
            Toast.fire({icon: "error", title: "Network errorss"}).then(() => navigate("/"));
        })


        // Load customers categories
        Customer.getCategories().then(res => {
            if (res.error) {
                return Toast.fire({icon: "error", title: "Network error. Go back a step and reload."}).then(() => {
                    navigate("/");
                })
            }
            setCategories(res.categories);
        })

    }, [filter])

    const searchCustomer =e => {
        if(e.target.value.trim().length> 0) {
            setFlter(true);
            let results = [];
            if (filterData === "name") {
                results = customersData.filter(customer => customer.name.toLowerCase().indexOf(e.target.value.toLowerCase())> -1);
            } else if (filterData === "location") {
                results = customersData.filter(customer => {
                    return  customer.address.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 || customer.state.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 || customer.city.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
                });
            } else {
                results = customersData.filter(customer => customer.phone.toString().indexOf(e.target.value.toLowerCase())> -1);
            }
            setCustomerFlter(results);
        } else {
            setFlter(false);
        }
    }

    const filterCustomerCategory = (e) => {
        if (e.target.value === "all") {
            // setFlter(false);
            return setCustomerFlter(customersData);
        }
        
        let categoryResults = [];
        categoryResults = customersData.filter(customer => customer.category == e.target.value);
        setFlter(true);
        setCustomerFlter(categoryResults);
        setSelectedCategory(e.target.value);
    }

    const handleSelectChange = (res) => {
       setSelectedCustomers(res.selectedRows);
        console.log(res);
    }

    const addToCategory = () => {
        Swal.fire({
            title: "Add " + selectedCustomers.length + " to category",
            input: "select",
            inputOptions: {Categories: Object.fromEntries(categories.map(c => [c.id, c.name]) ) },
            confirmButtonText: "Add to category",
            
        }).then(val => {
            if (!val.isConfirmed) {
                return;
            }
            const data = {customers: selectedCustomers.map(c => c.id), categoryID: val.value }
            Customer.addCustomersToCategory(data).then(res => {
                console.log(res);
                if (res.error) {
                    return Toast.fire({icon: "error", title: res.message});
                }
                Toast.fire({icon: "success", title: res.message});
            }).catch(err => {
                return Toast.fire({icon: "error", title: "Network error. GO back a step and try again."});
            })
        })
    }

    const sendGroupMessaage = () => {
        let confirmText = "Are you sure you want to send message to ";
        confirmText += (selectedCustomers.length > 1 )? `${selectedCustomers[0].fname} ${selectedCustomers[0].lname} and ${selectedCustomers.length - 1} others?` : selectedCustomers[0].fname + "?" ;
        Swal.fire({
            icon: "info",
            title: "Confirm",
            text: confirmText,
            showCancelButton: true,
            confirmButtonText: "Send Message"
        }).then(res => {
            if (res.isConfirmed) {
                const data = {customers: selectedCustomers.map(c => c.id), firstPerson: selectedCustomers[0].fname + " " + selectedCustomers[0].lname }
                Cookies.set("msg_recs", JSON.stringify(data));
                navigate("/send-message?type=group");
            }
        })
    }

    return  (
        <BodyWrapper page={"customer"}>
            {
                loading && <Loader data={"Customers"} />
            }
            <div className="row">
                <div className="col-sm-4 col-3">
                    <h4 className="page-title">Customers</h4>
                </div>
                <div className="col-sm-8 col-9 text-right m-b-20">
                    <Link to={"/customers/categories"} className="btn btn-primary btn-sm"><i className="fa fa-users"></i> Categories</Link>
                    &nbsp; &nbsp;
                    <Link to="/add-customer" className="btn btn btn-primary btn-rounded float-right" style={{backgroundColor: "#0a2d44", color: "#fff"}}><i className="fa fa-plus"></i> Add New Customer</Link>
                    
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-12 text-right m-b-1">
                    <div className="btn-group btn-group-sm">
                        {/* <button className="btn btn-white">CSV</button> */}
                        <CSVLink 
                        data={ 
                            filter ? 
                            customerFilter.map(customer => ({first_name: customer.fname, last_name: customer.lname, email: customer.email, address: customer.address, phone_number: customer.phone, phone_number2: customer.phone2, number_of_purchase: customer.purchases, date_registered: customer.date_added, last_purchase_date: customer.last_purchase, staff_name: customer.name}))
                            : 
                            customersData.map(customer => ({first_name: customer.fname, last_name: customer.lname, email: customer.email, address: customer.address, phone_number: customer.phone, phone_number2: customer.phone2, number_of_purchase: customer.purchases, date_registered: customer.date_added, last_purchase_date: customer.last_purchase, staff_name: customer.name}))
                        }  
                        className="btn btn-white"
                        >

                            <i className="fa fa-download fa-lg"></i> CSV Download
                        </CSVLink>
                    </div>
                </div>
            </div>

            <div className="row filter-row justify-content">
                <div className="col-sm-6 col-md-4">
                        <label className="focus-labelx">Search With</label>
                    <div className="form-group input-group">
                        <select className="form-control col-4" onChange={(e) => setFilterData(e.target.value)}>
                            <option value="name">Name</option>
                            <option value="phone">Phone</option>
                            <option value="location">Location</option>
                        </select>
                        <input type="text" className="form-control floatings" placeholder="Search customer.." onChange={searchCustomer} />
                    </div>
                    
                </div>
                
                <div className="col-sm-6 col-md-4">
                    <label className="focus-label">Select customer Category</label>
                    <div className="form-group">
                        <select className="form-control " onChange={filterCustomerCategory}>
                            <option value="all">All Customers</option>
                            {
                                categories.map(type => {
                                    return <option value={type.id} selected={type.id == selectedCategory}>{type.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
            
            {
                selectedCustomers.length > 0 &&
                <div className="row filter-row justify-content">
                    <div className="col-sm-12 col-md-12 mb-2">
                        <button className="btn btn-primary mr-2 btn-sm" onClick={addToCategory}>
                            <i className="fa fa-plus"></i> Add to category
                        </button>
                        <button className="btn btn btn-primary mr-2 btn-sm" onClick={sendGroupMessaage}>
                            <i className="fa fa-comment"></i> Send Message
                        </button>

                        {/* <a href="#" className="btn btn btn-danger "><i className="fa fa-trash"></i> Remove account</a> */}
                    </div>
                </div>
            }

            {/* Data table  */}
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        {
                            filter ?
                            <DataTable columns={columns} data={customerFilter} selectableRows onSelectedRowsChange={handleSelectChange} />
                            :
                            <DataTable columns={columns} data={customersData} selectableRows onSelectedRowsChange={handleSelectChange} />
                        }
                     
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default Customers;