import { Link, useNavigate } from "react-router-dom";
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import user from "../../assets/img/user.jpg";
import { Staff } from "../../api";
import { useEffect, useState } from "react";
import Toast from "../../components/Toast";
import Loader from "../../components/Loader";

const staffRolesCodes = {sales_rep: "Sales Representative", receptionist: "Receptionist", admin: "Administrator"};

const Staffs = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [staffs, setStaffs] = useState([]);

    useEffect(() => {
        Staff.allStaffs({}).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: "Network error. Go back a step and refresh"}).then(() => navigate("/"));
            }
            console.log(res);
            setStaffs(res.staffs);
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error. Go back a step and refresh"}).then(() =>navigate("/"));
        })
    }, [])

    return  (
        <BodyWrapper page={"staff"}>
            
            {
                loading && <Loader data={"Staffs"} />
            }
            <div className="row">
                <div className="col-sm-4 col-3">
                    <h4 className="page-title">Staffs</h4>
                </div>
                <div className="col-sm-8 col-9 text-right m-b-20">
                    <Link to="/add-staff" className="btn btn btn-primary btn-rounded float-right" style={{backgroundColor: "#0a2d44", color: "#fff"}}><i className="fa fa-plus"></i> Add New Staff</Link>
                </div>
            </div>
            <div className="row doctor-grid">
                {
                    staffs ?
                    staffs.sort((a, b) => new Date(b.date_added).getTime() - new Date(a.date_added).getTime()).map(staff => {
                        return (
                            <div className="col-md-4 col-sm-4  col-lg-3">
                                <div className="profile-widget">
                                    <div className="doctor-img">
                                        <Link className="avatar" to={"/staffs/" + staff.id}><img alt="" src={staff.avatar_url || user} /></Link>
                                    </div>
                                    <div className="dropdown profile-action">
                                    </div>
                                    <h4 className="doctor-name text-ellipsis"><Link to={"/staffs/" + staff.id}>{staff.fname} {staff.lname}</Link></h4>
                                    <div className="doc-prof">{staffRolesCodes[staff.role]}</div>
                                </div>
                            </div>
                        )
                    })
                    :
                    <p></p>
                }

            </div>
            {/* <div className="row">
                <div className="col-sm-12">
                    <div className="see-all">
                        <a className="see-all-btn" href="javascript:void(0);">Load More</a>
                    </div>
                </div>
            </div> */}
        </BodyWrapper>
    )
}

export default Staffs;