
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import user from "../../assets/img/user.jpg";
import { useEffect } from "react";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import Toast from "../../components/Toast";
import { Message } from "../../api";
import { useState } from "react";
import Swal from "sweetalert2";

const MessageDetails = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [message, setMessage] = useState({});

    useEffect(() => {
        if(!id) {
            return Toast.fire({icon: "error", title: "Invalid link followed. Go back a step and refresh"})
            .then(() => navigate("/messsages"))
        }

        Message.messageDetails(id).then(res => {
            if(res.error) {
                return Toast.fire({icon: "error", title: "Network error. Go back a step and refresh."})
                .then(() => navigate("/messsages"))
            }
            setMessage(res.messageDetail);
        }).catch(() => {
            Toast.fire({icon: "error", title: "Network error. Go back a step and refresh."})
            .then(() => navigate("/messsages"))
        })

    }, []);

    const resendMessage = () => {
        Swal.fire({
            title: `Are you sure?`,
            text: `Are you sure you want to resend this Message? Same message content will be sent to same recipients immediately.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "lighgreen",
            showConfirmButton: true,
            cancelButtonText: "Ohh No",
            confirmButtonText: "Resend now"
        }).then(res => {
            if (res.isConfirmed) {
                Message.resendMessage(id).then(() => {
                    Toast.fire({icon: "success", message: "Message resent successfully!"});
                }).catch(err => {
                    const error = err.response?.data || {message: "Network error"};
                    Toast.fire({icon: "error", title: error.message});
                })
            }
        })
    }

    const deleteMessage = () => {
        Swal.fire({
            title: `Are you sure?`,
            text: `Are you sure you want to delete this Message? It will be permanently removed.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "red",
            showConfirmButton: true,
            cancelButtonText: "Ohh No",
            confirmButtonText: "Yes, delete permanently"
        }).then(resp => {
            if (resp.isConfirmed) {
                Message.deleteMessage(id).then(res => {
                    if (res.error) {
                        return Toast.fire({icon: "error", title: res.message});
                    }
                    navigate("/messages");
                }).catch(err => {
                    Toast.fire({icon: "error", title: "Network error. Go back a step and retry"});
                })
            }
        })
    }

    return  (
        <BodyWrapper page={"message"}>
            <div class="row">
                    <div className="col-12 text-left mj-bj-30">
                        <Link to="/messages" className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Back to Messages</Link>
                    </div>
                <div class="col-sm-12">
                    <h4 class="page-title"> Message Details</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card-box">
                        <div class="mailview-content">
                            <div class="mailview-header">
                                <div class="row">
                                    <div class="col-sm-9">
                                        <div class="text-ellipsis m-b-10">
                                            <span class="mail-view-title">{message.message_reason}</span>&nbsp;
                                            { message.status && (
                                                message?.status.toLowerCase() === "sent" ?
                                                <span class="custom-badge status-green">{message.status}</span>
                                                :
                                                message?.status.toLowerCase() === "pending" ?
                                                <span class="custom-badge status-purple">{message.status}</span>
                                                :
                                                <span class="custom-badge status-red">{message.status}</span>)
                                            }
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="mail-view-action">
                                            <div class="btn-group">
                                                {
                                                    message.status !== "Sent" && 
                                                    <button type="button" class="btn btn-white btn-sm" data-toggle="tooltip" title="Resend Message" onClick={resendMessage}> <i class="fa fa-refresh"></i> Resend</button>
                                                }
                                                <button type="button" class="btn btn-white btn-sm" data-toggle="tooltip" title="Delete" onClick={deleteMessage}> <i class="fa fa-trash-o"></i> Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sender-info">
                                    <div class="sender-img">
                                        <img width="40" alt="" src={user} class="rounded-circle" />
                                    </div>
                                    <div class="receiver-details float-left">
                                        <span class="sender-name">Sent by <b>{message.fname + " " + message.lname} </b></span>
                                        <span class="receiver-name">
                                            Recipient type: <span><b>{message.recipient_type && message.recipient_type.toUpperCase()}</b></span>
                                            {/* , <span>Paul</span> */}
                                        </span>
                                        <br />
                                        <span>
                                            Type: 
                                            <b> {message.message_type}</b>
                                        </span>
                                    </div>
                                    <div class="mail-sent-time"> 
                                        <span class="mail-time">Initiated: {new Date(message.date_initiated).toLocaleString()}</span>
                                        <br/>

                                        <span class="mail-time">Schedule Type: <b>{message.scheduled ? "Scheduled" : ""} {message.recurring ? "Recurring" : ""} {(message.scheduled || message.recurring) ? "" : "Immediate"}</b></span>

                                        <br/>
                                        {(!message.scheduled && !message.recurring) && <span class="mail-time">Sent: {message.status === "Sent" && new Date(message.date_sent).toLocaleString()}<br/></span>}
                                        
                                        {message.scheduled? <span class="mail-time">Schedule Date/Time: <b>{ new Date(message.schedule_date).toLocaleString()}</b></span> : ""}

                                        {message.recurring ? <span class="mail-time">Recurring Type: <b>{ message.recurring_type}</b></span> : ""}
                                        <br />
                                        {message.recurring ? <span class="mail-time">Recurring Time: <b>{ new Date(message.schedule_date).toLocaleTimeString()}</b></span> : ""}
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div class="mailview-inner" style={{minHeight: "200px"}}>
                                <p>{message.message_body}</p>
                                
                            </div>
                        </div>
                        <div class="mail-attachments">
                            {/* <p><i class="fa fa-paperclip"></i> 2 Attachments - <a href="#">View all</a> | <a href="#">Download all</a></p> */}
                        </div>
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default MessageDetails;