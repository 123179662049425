
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import logo from "../../assets/img/brandblocx_logo.png"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import Toast from "../../components/Toast";
import { Sale } from "../../api";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const SaleDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [sale, setSale] = useState({});
    const saleID = location.search.split("=")[1];

    useEffect(() => {
        if(!saleID) {
            return Toast.fire({icon: "error", title: "Data error. Go back a step and refresh."})
            .then(() => navigate("/sales"));
        }
        Sale.saleDetails(saleID).then(res => {
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => {
                    navigate("/sales")
                })
            }
            console.log(res);
            setSale(res.saleDetails);
        }).catch((err) => {
            console.log(err);
            Toast.fire({icon: "error", title: "Data error. Go back a step and refresh."})
            .then(() => navigate("/sales"))
        })
    }, [])

    const Print = () => {
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
       document.body.innerHTML = originalContents; 
      }

    return  (
        <BodyWrapper  page={"sale"}>
            <div className="row">
                    <div className="col-12 text-left mj-bj-30">
                        <Link to="/sales" className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Sales</Link>
                    </div>
                <div className="col-sm-5 col-4">
                    <h4 className="page-title">Sale Details</h4>
                </div>
                <div className="col-sm-7 col-8 text-right m-b-30">
                    <div className="btn-group btn-group-sm">
                        <button className="btn btn-white" onClick={Print}><i className="fa fa-print fa-lg"></i> Print</button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card" id="printablediv">
                        <div className="card-body">
                            <div className="row custom-invoice">
                                <div className="col-6 col-sm-6 m-b-20">
                                    <img src={logo} className="inv-logo" alt="" />
                                    <h4>Brandblocx Technologies</h4>
                                    <ul className="list-unstyled">
                                        <li></li>
                                        <li></li>
                                        <li>Sampresh building, Opposite university of Ibadan,</li>
                                        <li>International Conference center, Sango-Ojoo expressway,</li>
                                        <li>Orogun, Ibadan.</li>
                                        <li>GST No: 08126430670, 08050885112</li>
                                    </ul>
                                </div>
                                <div className="col-6 col-sm-6 m-b-20">
                                    <div className="invoice-details">
                                        <h3 className="text-uppercase">#REF-{sale.id && sale.id.toString().padStart(4,"0")}</h3>
                                        <ul className="list-unstyled">
                                            <li>Date: <span> {sale.date_recorded && new Date(sale.date_recorded).toDateString().slice(8,15).replace(" ", " " + months[new Date(sale.date_recorded).getMonth()] + ", " )}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-lg-6 m-b-20">
                                    
                                        <h5>Reciept to:</h5>
                                        <ul className="list-unstyled">
                                            <li>
                                                <h5><strong>{sale.c_fname} {sale.c_lname}</strong></h5>
                                            </li>
                                            <li><span>{sale.address}</span></li>
                                            <li>{sale.city && sale.city + ", "}{sale.state} State</li>
                                            <li>{sale.phone}</li>
                                            <li>{sale.email}</li>
                                        </ul>
                                    
                                </div>
                                <div className="col-sm-6 col-lg-6 m-b-20">
                                    <div className="invoices-view">
                                        <span className="text-muted">Payment Details:</span>
                                        <ul className="list-unstyled invoice-payment-details">
                                            <li>
                                                <h5>Total Amount: <span className="text-right">&#8358;{sale.amount && sale.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span></h5>
                                            </li>
                                            <li>Payment Method: <span>{sale.payment_method}</span></li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>ITEM(S)</th>
                                            <th>PRICE</th>
                                            <th>QUANTITY</th>
                                            <th>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        sale.items &&
                                        sale.items.map(item => {
                                            return (
                                            <tr>
                                                <td></td>
                                                <td>{item.p_name}</td>
                                                <td>&#8358;{item.price.toLocaleString()}</td>
                                                <td>{item.quantity}</td>
                                                <td>&#8358;{Math.ceil(item.quantity * item.price).toLocaleString()}</td>
                                            </tr>
                                            )
                                        })
                                    }
                                       
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <div className="row invoice-payment">
                                    <div className="col-sm-7">
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="m-b-20">
                                            <h6>Total paid</h6>
                                            <div className="table-responsive no-border">
                                                <table className="table mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <th>Subtotal:</th>
                                                            <td className="text-right">&#8358;{sale.amount && sale.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tax: <span className="text-regular">(0%)</span></th>
                                                            <td className="text-right">&#8358;0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Total:</th>
                                                            <td className="text-right text-primary">
                                                                <h5>&#8358;{sale.amount && sale.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h5>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div><br /><br /><br />
                                <div className="invoice-info">
                                    <h5>Terms and Conditions</h5>
                                    <p className="text-muted">All goods cannot be returned after 7 days of purchase and shipping from the store. Thanks for your understanding.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default SaleDetails;