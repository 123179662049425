
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Customer, Product, Sale, Staff } from "../../api";
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import converter from "number-to-words";

const RecordSale = () => {

    const navigate = useNavigate();
    const customerID = useLocation().search.split("=")[1];
    const [customer, setCustomer] = useState({});

    const [products, setProducts] = useState([]);
    const [items, setItems] = useState([{product: "", quantity: 1, price: 0}]);
    const [pageLoading, setPageLoading] = useState(true);

    const [form, setForm] = useState({customerID,  paymentMethod: "Transfer", payerBank: "", saleDate: "", item_types: 1, deliveryMode: "pickup" });
    const [loading, setLoading] = useState(false);

    const inputChange = e => {
        setForm({...form, [e.target.name]: e.target.value });
    }
    
    const addProduct = () => {
        setForm({ ...form, item_types: form.item_types+1 });
        items.push({product: "", quantity:1, price: 0});
        setItems(items);
    }

    const removeLastProduct = () => {
        items.pop();
        setItems(items);
        setForm({...form, item_types: form.item_types-1});
    }
    const itemProductChange = e => {
        const name = e.target.name;
        const index = parseInt(name.slice(4));
        const productPicked = items.some(item => item.product == e.target.value);
        if (!productPicked) {
            items[index].product = e.target.value;
            items[index].price = products.find(product => product.id == e.target.value ).price * items[index].quantity;
            setItems(items);
        }
    }

    const itemQuantityChange = (e) => {
        const name = e.target.name;
        const index = parseInt(name.slice(4));
        items[index].quantity = parseInt(e.target.value);
        items[index]["price"] = products.find(product => product.id == items[index].product).price * e.target.value;
        setItems(items);
    }

    const itemPriceChange = (e, index) => {
        const allItems = items;
        allItems[index].price = e.target.value.length > 2 ? parseInt(e.target.value.replace("₦", "").replace(",", "")) : e.target.value.replace("₦", "");
        setItems(allItems);
    }

    useEffect(()=> {
        let mounted = true;
        if (mounted) {
            Product.allProducts().then(res => {
                setPageLoading(false);
                if(res.error) {
                    return Toast.fire({icon: "error", title: res.message});
                }
                setProducts(res.products.filter(product => product.quantity > 0));
            })
        }

        Customer.customerProfile(customerID).then(res => {
            if(res.error) {
                return Toast.fire({icon: "error", title: res.error}).then(() => navigate("/customers"));
            }
            setCustomer(res.customer);
            setForm({...form, payerName: res.customer.fname + " " + res.customer.lname});
        }).catch(() => Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/customers")) )

        return () => {
            mounted = false;
        }
    }, [products])

    const submitForm = e => {
        e.preventDefault();
        setLoading(true);
        Sale.recordSale({...form, items}).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message});
            }

            Toast.fire({icon: "success", title: res.message}).then(() => {
                // navigate("/payment-print/" + res.data.saleRef);
                navigate("/sales/" + res.data.saleRef + "?sid=" + res.data.saleID);
            } );
        }).catch(() => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error. Go back a step and refresh."})
        })
    }

    return  (
        <BodyWrapper page={"sale"}>
        {
            pageLoading && <Loader data={"Data"} />
        }
        <div className="row">
            <div className="col-lg-8 offset-lg-2">
                <h4 className="page-title">Record Sale</h4>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-8 offset-lg-2">
                <form onSubmit={submitForm}>
                    <div className="row">
                        <div className="col-md-6">
                            <h4>Sale Details</h4>
                            <hr />
                        </div>
                    </div>
                        
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Customer ID</label>
                                <input className="form-control" type="text" value={"CUS-" + customerID.padStart(5, "0")} readOnly="" onChange={inputChange} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Customer Name</label>
                                <input className="form-control" type="text" value={customer.fname + " " + customer.lname} readOnly="" onChange={inputChange} />
                            </div>
                        </div>
                    </div>

                    {
                        items.map((val, index) => {
                            return (
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Select Product {index + 1}</label>
                                            <select name={"item" + index} id="" className="form-control" onChange={itemProductChange} value={items[index].product}>
                                                <option value="">--Select Product--</option>
                                                {
                                                    products && products.map(product => {
                                                        return <option value={product.id}>{product.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Quantity</label>
                                            <input type="number" className="form-control" name={"item" + index} value={items[index].quantity} min="1" max={products.find(p => p.id == items[index].product)?.quantity} onChange={(e) => itemQuantityChange(e, index)} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Price</label>
                                            <input type="text" className="form-control" name="amount" value={"₦" + (items[index].price)} onChange={(e) => itemPriceChange(e, index)} />
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            )
                        })
                    }
                    <button type="button" className="btn btn-primary btn-sm" onClick={addProduct}><i className="fa fa-plus"></i> Add product</button> &nbsp;
                    {
                        items.length > 1 && <button type="button" className="btn btn-danger btn-sm" onClick={removeLastProduct}><i className="fa fa-trash"></i> Remove last</button>
                    }
                    
                    <br /><br /><br /><br />
                    
                    <div className="row">
                        <div className="col-md-6">
                            <h4>Payment Details</h4>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Total Amount</label>
                                <div className="cal-icon">
                                    <input type="text" className="form-control" name="amount" value={"₦" + items.map(item => item.price).reduce((prev,val) => parseInt(prev) + parseInt(val))?.toLocaleString() } readOnly />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Total Amount in words</label>
                                <div className="cal-con">
                                    <input 
                                    type="text"
                                    className="form-control"
                                    name="amountWords"
                                    onChange={inputChange}
                                    value={ isNaN(items.map(i => i.price).reduce((prev,val) => parseInt(prev) + parseInt(val))) || items.map(i => i.price).reduce((prev,val) => parseInt(prev) + parseInt(val)) < 1 ? "" : 
                                        converter.toWords(items.map(i => i.price).reduce((prev,val) => parseInt(prev) + parseInt(val))).charAt(0).toLocaleUpperCase() + converter.toWords(items.map(i => i.price).reduce((prev,val) => parseInt(prev) + parseInt(val))).slice(1) + " Naira"}
                                    readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Payment Method</label>
                                <select className="form-control" name="paymentMethod" onChange={inputChange}>
                                    <option value={"Transfer"}>Transfer</option>
                                    <option value={"Cash"}>Cash</option>
                                    <option value={"POS"}>POS</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Sale Date and Time</label>
                                <input className="form-control" type={"datetime-local"} name="saleDate" onChange={inputChange} />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Payer's Bank (For Transfer and POS)</label>
                                <input className="form-control" type="text" name="payerBank"  value={form.payerBank} onChange={inputChange} />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Delivery Mode</label>
                                <select className="form-control" name="deliveryMode" onChange={inputChange}>
                                    <option value={"pickup"}>Pick up</option>
                                    <option value={"delivered"}>Delivered</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <li class="list-group-item">
                                    Send Package ready SMS
                                    <div class="material-switch float-right">
                                        <input id="staff_module" type="checkbox" />
                                        <label for="staff_module" class="badge-success"></label>
                                    </div>
                                </li>
                            </div>
                        </div>
                    </div>
                    <div className="m-t-20 text-center">
                        <button className="btn btn-primary submit-btn">{loading ? "Recording Sale..." : "Record Sales"}</button>
                    </div>
                </form>
            </div>
        </div>
        </BodyWrapper>
    )
}

export default RecordSale;