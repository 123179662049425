
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Customer, Message } from "../../api";
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import Toast from "../../components/Toast";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import Cookies from "js-cookie";

const SendMessage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [recipientType, setRecipientType] = useState("");
    const [firstRecipient, setFirstRecipient] = useState("");
    const [category, setCategory] = useState({});
    const [form, setForm] = useState({reason: "", type: "sms", body: "", subject: "", scheduleType: "immediate", scheduleDate: "", recipients: ""});
    const [recUser, setRecUser] = useState({});
    const [loading, setLoading] = useState(false);

    const inputChange = e => {
        setForm({...form, [e.target.name]: e.target.value });
    }

    const cid = location.search && location.search.indexOf("cid") > -1 && location.search.split("=")[1];
    const catid = location.search && location.search.indexOf("catid") > -1 && location.search.split("=")[1];
    const recType = location.search && location.search.indexOf("type") > -1 && "group";

    useEffect(() => {
        if(cid) {
            setRecipientType("one");
            Customer.customerProfile(cid).then(res => {
                if(res.error) {
                    return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/customers"));
                }
                setRecUser(res.customer);
                setForm({...form, recipients: cid});
            }).catch(err => {
                Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/customers"));
            })
        } else if (catid) {
            setRecipientType("category");
            Customer.getCategories().then((res) => {
                if(res.error) {
                    return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/customers"));
                }
                const category = res.categories.find(cat => cat.id == catid);
                setCategory(category);
                setForm({...form, recipients: catid});
            })
        } else if (recType === "group") {
            setRecipientType("random");
            let recipientData = Cookies.get("msg_recs");
            if (!recipientData) {
                return Toast.fire({icon: "error", title: "Customers selection error. Go back and reselect customers"})
                .then(() => navigate("/customers"));
            }
            recipientData = JSON.parse(recipientData);
            setForm({...form, recipients: recipientData.customers});
            setFirstRecipient(recipientData.firstPerson);
        } else {
            setRecipientType("all");
            setForm({...form, recipients: "all"});
        }
    }, []);

    const submitMessageForm = (e) => {
        e.preventDefault();
        setLoading(true);
        
        Message.sendMessage({...form, recipientType}).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message});
            }
            Cookies.remove("msg_recs");
            Toast.fire({icon: "success", title: res.message}).then(() => navigate("/messages"));
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error. GO back a step and retry."});
        })
    }

    return  (
        <BodyWrapper page={"message"}>
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <h4 className="page-title">Send Message</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <form onSubmit={submitMessageForm}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Message Recipient(s)</label>
                                    <input 
                                    type="text"
                                    className="form-control"
                                    readOnly 
                                    value={
                                        recipientType === "category" ? category.name + " Category" : 
                                        recipientType == "all" ? "All Customers" : recipientType === "one" ? recUser.fname + " " + recUser.lname : `${firstRecipient} and ${form.recipients.length - 1} others`
                                    } 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Message Reason</label>
                                    <input type="text" className="form-control" name="reason" value={form.reason} onChange={inputChange} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Message Type</label>
                                    <select className="form-control" name="type" onChange={inputChange}>
                                        <option value={"sms"}>Text Message</option>
                                        <option value={"email"}>Email</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                form.type === "email" && 
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Email Subject</label>
                                        <input type="text" className="form-control" name="subject" value={form.subject} onChange={inputChange} />
                                    </div>
                                </div>
                            }
                        </div>

                        {
                            form.type === "email" ?
                            <ReactQuill  
                            theme="snow" 
                            style={{height: "150px", marginBottom: "50px"}}
                            onChange={(val) => setForm({...form, body: val})} 

                            />
                            :
                            <div className="form-group">
                                <label>Message</label>
                                <textarea cols="30" rows="4" className="form-control" name="body" value={form.body} onChange={inputChange}></textarea>
                            </div>
                        }


                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>When do you want to send message?</label>
                                    <select className="form-control" name="scheduleType" onChange={inputChange}>
                                        <option value={"immediate"}>Send Now</option>
                                        <option value={"scheduled"}>Schedule Message</option>
                                        <option value={"recurring"}>Set as recurring Message</option>
                                    </select>
                                </div>
                            </div>
                            {
                                form.scheduleType === "scheduled" && 
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Select date and time</label>
                                        <input type="datetime-local" className="form-control" name="scheduleDate" value={form.scheduleDate} onChange={inputChange} />
                                    </div>
                                </div>
                            }
                            {
                                form.scheduleType === "recurring" && 
                                <div className="row col-12">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>When do you want this message delivered?</label>
                                            <select className="form-control" name="recurringType" onChange={inputChange}>
                                                <option value={"months_first_day"}>Every first day of the month</option>
                                                <option value={"months_last_day"}>Every last day of the month</option>
                                                <option value={"months_mid_day"}>Every mid of the month</option>
                                                <option value={"sundays"}>Every Sundays</option>
                                                <option value={"mondays"}>Every Mondays</option>
                                                <option value={"tuesdays"}>Every Tuesdays</option>
                                                <option value={"wednesdays"}>Every Wednesdays</option>
                                                <option value={"thursdays"}>Every Thursdays</option>
                                                <option value={"fridays"}>Every Fridays</option>
                                                <option value={"saturdays"}>Every Saturdays</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Select time</label>
                                            <input type="time" className="form-control" name="recurringTime" onChange={inputChange} />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="m-t-20 text-center">
                            <button className="btn btn-primary ">{loading ? "Sending..." : (recipientType !== "one" ? "Send to customers" : "Send to " + recUser.fname + " " + recUser.lname )}</button>
                        </div>
                    </form>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default SendMessage;